import Typography from "typography"
import lincolnTheme from "typography-theme-lincoln"

lincolnTheme.bodyFontFamily = ["Montserrat", "sans-serif"]

// lincolnTheme.overrideThemeStyles = () => {
//   return {
//     "a.gatsby-resp-image-link": {
//       boxShadow: `none`,
//     },
//   }
// }

const typography = new Typography(lincolnTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
